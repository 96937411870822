/**
 * Form text - Component
 */

.cmp-form-text input {
  background-color: var(--color-white);
  height: 2.5rem; /* 40 */
  padding: 0 var(--spacing-sm);
  border-radius: var(--border-radius-xs);
  margin-bottom: var(--spacing-xxs);
}

.cmptext input::placeholder,
.cmptext input:placeholder,
.cmp-form-text input::placeholder {
  color: var(--color-gray-500);
}

.cmp-form-text__textarea {
  padding: var(--spacing-sm) var(--spacing-sm);
  resize: none;
  border-radius: var(--border-radius-xs);
  margin-bottom: var(--spacing-xxs);
}

.cmp-form-text[data-cmp-required-message] label::after {
  content: ' *';
}

/* Date */
.cmp-form-text__text[type='date'] {
  display: flex;
  align-items: center;

  &::after {
    font-family: 'custom_materialicons', sans-serif;
    content: var(--icon-calendar);
    font-size: var(--sm-font-size-h4);
  }
}

.cmp-form-text__text[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

.cmp-form-text__text[type='date']::-webkit-inner-spin-button,
.cmp-form-text__text[type='date']::-webkit-clear-button {
  z-index: 1;
}
