@import '@react/common.scss';

/**
 * Form - Component
 */

/* stylelint-disable-next-line selector-no-qualifying-type */
form.cmp-form {
  display: flex;
  flex-wrap: wrap;
  --form-field-scroll-margin-top: 88px; /* keep synced with FORM_FIELD_SCROLL_MARGIN_TOP */
}

.cmp-form-text label,
.cmp-form-options__label,
.cmp-form-options__legend {
  @include regular-md();

  color: var(--color-gray-900);
  margin-bottom: var(--spacing-xs);
  display: inline-block;
}

.cmp-form-options__description {
  @include regular-lg();

  margin-bottom: var(--spacing-md);
}

.cmp-form-options__label::first-letter,
.cmp-form-options__legend::first-letter,
.cmp-form-text label::first-letter {
  text-transform: uppercase;
}

.cmp-form-text .iti {
  width: 100%;
}

.cmp-form-text__text,
.cmp-form-text__textarea,
.cmp-form-file,
.cmp-form-text .iti {
  border: var(--spacing-xxxs) solid var(--color-gray-500);
  order: 2;
  position: relative;
}

.cmp-form-options .c-custom-select-container:not(.is-open):focus-within {
  outline: var(--border-m) solid var(--color-focus-500);
  box-shadow: none;
}

.cmp-form-options .c-custom-select-container {
  outline: var(--border-xs) solid var(--color-gray-500);
  position: relative;
  border-radius: var(--border-radius-xs);
}

.cmp-form-options .is-open .c-custom-select-opener {
  background: var(--color-gray-50);
}

.cmp-form-options .c-custom-select-container.is-open {
  border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;

  &::after {
    content: "";
    display: block;
    height: var(--spacing-xxxs);
    background-color: var(--color-gray-700);
    position: absolute;
    bottom: 0;
    left: var(--spacing-sm);
    right: var(--spacing-sm);
  }
}

.cmp-form-options .c-custom-select-panel {
  border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
  box-shadow:
    calc(var(--spacing-xxxs) * -1) 0 0 0 black,
    var(--spacing-xxxs) 0 0 0 black,
    0 var(--spacing-xxxs) 0 0 black;
  background: var(--color-gray-50);
  padding: var(--spacing-xs) 0;
}

.cmp-form-options .c-custom-select-option {
  padding: 0 var(--spacing-sm);
}

.cmp-form-file {
  display: flex;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.cmp-form-file input[type='file'] {
  display: none;
}

.cmp-form-text.is-invalid .cmp-form-file,
.cmp-form-text.is-empty .cmp-form-file {
  border-color: var(--color-primary-500);
}

.cmp-form-text.is-correct .cmp-form-file .cmp-form-file__text {
  color: var(--color-gray-500);
}

.cmp-form-file .cmp-form-file__button:active,
.cmp-form-file .cmp-form-file__button:focus {
  outline: var(--color-focus-500) solid 0.188rem;
  border-radius: 0.125rem;
}

.cmp-form-file .cmp-form-file__button:disabled {
  background-color: var(--color-gray-100);
  color: var(--color-gray-500);
}

.cmp-form-file .cmp-form-file__button {
  @include bold-lg();

  background-color: var(--color-gray-500);
  color: var(--color-white);
  padding: var(--spacing-xs) 1.25rem;
  text-align: center;
  cursor: default;
}

.cmp-form-file .cmp-form-file__text {
  @include regular-sm();

  padding: var(--spacing-sm) var(--spacing-xl) var(--spacing-sm) var(--spacing-xs);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--color-gray-300);
}

.cmp-form .form-field {
  scroll-margin-top: var(--form-field-scroll-margin-top);
}

.iti .cmp-form-text__text {
  width: 100%;
  border: none;
}

.iti .cmp-form-text__text.hidden {
  visibility: hidden;
  display: inline-block;
  height: 0;
  width: 0;
  padding: 0;
}

.cmp-form-text__help-block,
.cmp-form-options__help-message {
  @include regular-sm();

  color: var(--color-gray-500);
  margin: 0;
  order: 3;
}

.cmp-form-text,
.cmp-form-options {
  @include regular-md();

  display: flex;
  flex-flow: column;
  letter-spacing: 0;
  margin: 0;
  margin-bottom: var(--spacing-md);
  position: relative;
}

.cmp-form-text__text:focus,
.cmp-form-text__textarea:focus {
  border: var(--border-s) solid var(--color-black);
  outline: var(--border-m) solid var(--color-focus-500);
}

.cmp-form-text__text:active,
.cmp-form-text__textarea:active {
  border: var(--border-s) solid var(--color-black);
}

.c-custom-select-container:focus-within {
  outline-width: var(--spacing-xxxs);
  outline-color: var(--color-gray-700);
  outline-style: solid;
  box-shadow: var(--box-shadow-md);
}

.cmp-form-options__field-arrow {
  margin-left: auto;
}

.cmp-form-options__field-arrow:focus {
  outline-style: none;
  color: var(--color-primary-500);
}

/* disabled fields */
.cmp-form .cmp-form-text__text:read-only,
.cmp-form .cmp-form-text__textarea:read-only,
.cmp-form .c-custom-select-opener--read-only {
  background-color: var(--color-gray-50);
  border: var(--spacing-xxxs) solid var(--color-gray-300);
}

.cmp-form input:disabled {
  border: 0.125rem solid var(--color-gray-300);
}

.cmp-form input:disabled + span {
  color: var(--color-gray-300);
}

/* validation */
.cmp-form .is-invalid input,
.cmp-form .is-invalid textarea,
.cmp-form .is-invalid .iti,
.cmp-form .is-empty textarea,
.cmp-form .is-empty input,
.cmp-form .is-empty .iti,
.cmp-form .is-empty .c-custom-select-container,
.cmp-form .is-invalid .c-custom-select-container {
  border: var(--spacing-xxxs) solid var(--color-primary-500);
  box-shadow: none;
}

.cmp-form .cmp-countriesoptions__country.is-empty::before,
.cmp-form .cmp-countriesoptions__state.is-empty::before,
.cmp-form .cmp-form-options--drop-down.is-empty::before {
  right: 2.3rem;
  top: 2.45rem;
}

.cmp-form .is-correct textarea,
.cmp-form .is-correct input:not([type='radio'], [type='checkbox']),
.cmp-form .is-correct .iti,
.cmp-form .is-correct .c-custom-select-container {
  border: var(--spacing-xxxs) solid var(--color-success-500);
  box-shadow: none;
}

.cmp-form .cmp-countriesoptions__country.is-correct::before,
.cmp-form .cmp-countriesoptions__state.is-correct::before,
.cmp-form .cmp-form-options--drop-down.is-correct::before {
  right: 3.125rem;
  top: 2.45rem;
}

/* stylelint-disable selector-max-compound-selectors */
.cmp-form .is-invalid .iti input,
.cmp-form .is-empty .iti input {
  border: none;
}
/* stylelint-enable selector-max-compound-selectors */

.cmp-form .is-invalid::after {
  @include regular-xs();

  color: var(--color-primary-500);
  content: attr(data-cmp-constraint-message);
  order: 3;
}

.cmp-form .is-empty::after,
.cmp-form .is-empty ~ .cmp-required-message::after {
  @include regular-xs();

  color: var(--color-primary-500);
  content: attr(data-cmp-required-message);
  order: 3;
}

.cmp-form .is-invalid:not(.cmp-form-text__is-textarea)::before,
.cmp-form .is-empty:not(.cmp-form-text__is-textarea)::before {
  @include bold-lg();

  color: var(--color-primary-500);
  content: '!';
  position: absolute;
  right: var(--spacing-xxxl);
  top: 2.25rem; /* 36 */
  z-index: 1;
}

.cmp-form .cmp-form-options--radio.is-invalid::before {
  content: '';
}

.cmp-form .is-correct:not(.customSelect, .cmp-form-options)::before {
  top: 2.45rem;
  right: 0.938rem;
}

.cmp-form .is-correct:not(.cmp-form-options--radio, .cmp-form-options--checkbox, .cmp-form-text__is-textarea)::before {
  content: '';
  display: inline-block;
  position: absolute;
  z-index: 2;
  transform: rotate(45deg);
  height: 0.875rem;
  width: 0.438rem;
  border-bottom: 0.188rem solid var(--color-success-500);
  border-right: 0.188rem solid var(--color-success-500);
}

.cmp-form .c-custom-select-container.is-correct::before {
  top: 0.75rem;
  right: 3.125rem;
}

.cmp-form .is-invalid input::after,
.cmp-form .is-empty input::after {
  content: none;
}

.cmp-form .is-empty .cmp-form-options__field-description {
  color: var(--color-primary-500);
}

.cmp-form .cmp-form-options__field-label.is-empty::before {
  content: none;
}

/* separator */
.cmp-form .separator {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
}

/* captcha */
.is-empty.cmp-form-captcha::before {
  content: attr(data-cmp-required-message);
  position: initial;
}

.cmp-form__error-message,
.cmp-form__success-message {
  @include regular-sm();

  order: 3;
  margin-top: var(--spacing-xxs);
}


.cmp-form-text__characters-remaining {
  @include regular-sm();

  position: absolute;
  right: var(--spacing-xxs);
  bottom: var(--spacing-xs);
  z-index: 1;
}

.cmp-form-text__characters-remaining:has(~ .cmp-form__error-message) {
  bottom: var(--spacing-xl);
}

.cmp-form-text__characters-remaining.cmp-form-text__has-help-block {
  bottom: var(--spacing-lg);
}

.cmp-form-text__characters-remaining.cmp-form-text__has-help-block:has(~ .cmp-form__error-message) {
  bottom: var(--spacing-xxl);
}

.cmp-form__error-message {
  color: var(--color-error-500);
}

.cmp-form__success-message {
  color: var(--color-gray-500);
  display: none;
}

.is-correct .cmp-form__success-message {
  display: flex;
}

/* Override grid margin */

.cmp-form .cmp-form-button--wrapper.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 25%;
}

.cmp-form .cmp-form-button {
  display: flex;
  flex-direction: row;
}

.cmp-form.cmp-form--success .cmp-form-button {
  display: none;
}

.cmp-form.cmp-form--success .cmp-infobox {
  order: 2;
}

.cmp-form .cmp-form-button--loading .loading-spinner {
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  background: var(--color-primary-500);
  z-index: var(--zindex-filterbar-loading-overlay);
}

.cmp-form .cmp-button--primary-positive .cmp-form-button:disabled {
  background-color: var(--color-primary-500);
  display: flex;
  color: var(--color-white);
}

.cmp-form .cmp-form-button--loading .loading-spinner rect {
  fill: var(--color-primary-500);
}

.cmp-form .cmp-form-button:hover .loading-spinner rect {
  fill: var(--color-primary-700);
}

.cmp-form .cmp-form-button--loading {
  width: 100%;
  height: 100%;
}

.cmp-form .icon::before,
.cmp-form [class*=' icon-']::before {
  vertical-align: middle;
}

.cmp-form .cmp-button--primary-positive .cmp-form-button {
  white-space: nowrap;
}

/* columns */
@media (--breakpoint-up-sm) {
  .cmp-form .aem-GridColumn--default--3 {
    padding-right: var(--spacing-sm);
  }

  .cmp-form .aem-GridColumn--default--3 + .aem-GridColumn--default--3 {
    padding-left: var(--spacing-sm);
  }

  .cmp-form-options--is-horizontal {
    flex-flow: row;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .cmp-form-options--is-horizontal .cmp-form-options__field-label {
    margin-right: var(--spacing-xl);
  }

  .cmp-form-options--is-horizontal .cmp-form-options__description {
    display: flex;
    width: 100%;
    margin-bottom: 0;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-form hr {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
  }
}
